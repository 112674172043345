import React from 'react';

import "./RepoUser.css"
function RepoUser() {
  return (
    <div>
      Hello user that has no righ
     
    </div>
  );
}

export default RepoUser;