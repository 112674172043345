import "./Fornonadmin.css"

function Fornonadmin() {
  return (
    <div className="fornonadmin-container">
      Please wait for your administrator to add Drivers and Vehicles!
    </div>
  );
}

export default Fornonadmin;
